import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import authConfig, { auth0Config, msalConfig } from "authConfig";
import ConstruCoderAuthContext, {  ConstruCoderAuthUserProps, ConstruCoderProviderType, initialAuthContext }  from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { ls} from 'App';
export const useAuth = () => {
    // State
    const [ isLoading, setLoading] = useState(false);
    const [ appUser, setAppUser ] = useState<ConstruCoderAuthUserProps | undefined>(undefined);
    const [ statusCode, setStatusCode] = useState<any>(undefined);


    // Constants
    const provider: ConstruCoderProviderType = authConfig.provider;
    const auth = { ...initialAuthContext, provider: provider, isLoading: () => false, erroStatusKey: statusCode, appUser: appUser};
    const userKey = 'user';
    const erroStatusKey = "erroStatus";
    const tokenKey = 'token';
    const idTokenKey = 'idToken';
    const accessTokenKey = 'accessToken';
    const expiryTokenKey = 'tokenExpiry';
    const expiryAccessTokenKey = 'accessExpiry';
    const expiryIdtokenKey = 'idTokenExpiry';

    const getAvatar = (name: string) => {
        return "https://ui-avatars.com/api/?name=" + encodeURI(name) + "&background=random&size=96&rounded=true"
    }

    if (provider === 'aad') {
        const { instance, inProgress, accounts} = useMsal();
        const isAuthenticated = useIsAuthenticated();
        
        console.log(`Progress: ${inProgress} - Authenticated: ${isAuthenticated}`)
        const getToken = async (config: any): Promise<AuthenticationResult | undefined> => {
            
            const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
            let tk = ls.get(tokenKey);
            let expiry = ls.get(expiryTokenKey);
            // ls.set(erroStatusKey, 403);
            if (tk && expiry && expiry > currentTimestampInSeconds) {
                
                return tk;
            }
            try {
                
                const new_token =  await instance.acquireTokenSilent({ ...config, account: accounts?.[0] }); 
                if (new_token) {
                    
                    const { exp } = JSON.parse(atob(new_token.idToken.split('.')[1]));
                    let new_exp = exp *1000;
                    ls.set(expiryTokenKey, new_exp);
                    ls.set(tokenKey, new_token);
                }
                return new_token;
            } catch (error) {
                console.log(error)
            }
            return undefined;
        };
        auth.login = async () => await instance.loginRedirect({ ...msalConfig.loginRequest });
        auth.getAccessToken = async (config: any): Promise<string| undefined> => {
            try {
                var token = await getToken(config);
                if (token) {
                    return token.accessToken;
                }
                return undefined;                
            } catch (error) {
                console.log(error);
                return;
            }
        }; 

        auth.getUserInfo = async (config: any) => {
            auth.isLoading = () => true;
            let user: ConstruCoderAuthUserProps | undefined = ls.get(userKey);
            if (!user) {
                try {
                    let current_token = await getToken(config);
                    if (!current_token) {
                        user = undefined;
                    } else {
                        user = !((current_token.idTokenClaims as any)['roles'] as string[]) ?
                            undefined :
                            {
                                email: current_token.account?.username,
                                roles: (current_token.idTokenClaims as any)['roles'] as string[],
                                id_token: current_token.idToken,
                                name: current_token.account!.name,
                                profilePhoto: getAvatar(current_token.account!.name!),
                                isAdmin: ((current_token.idTokenClaims as any)['roles'] as string).includes('Admin')
                            } ;
                        
                        ls.set(userKey, user);
                        setAppUser(user);   
                    }
                } catch(ex) {
                    user = undefined;
                }
            }
            if (user && !user.roles) {
                ls.set(erroStatusKey, 403);
            }
            auth.isLoading = () => false;
                return user;
        }
        auth.logout = async () => {
            ls.remove(userKey);
            ls.remove(erroStatusKey);
            await instance.logout({ onRedirectNavigate: (url) => { }})
        }
        auth.isAuthenticated = () => {
            console.log(isAuthenticated);
            return isAuthenticated;
        };
        auth.isLoading = () =>  {
            console.log(`${inProgress} - ${!(!inProgress || inProgress === InteractionStatus.None)}`);
            return !(!inProgress || inProgress === InteractionStatus.None);
        };
        useEffect(() => {  auth.isAuthenticated = () => isAuthenticated}, [isAuthenticated]);
        useEffect(() => { setLoading(!inProgress || inProgress === InteractionStatus.None); } , [inProgress]);
        useEffect(() => { auth.isLoading = () => isLoading}, [isLoading]);


    } else if (provider === 'auth0') {
        const auth0 = useAuth0();
        const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
        const namespace = auth0Config.auth0_namespace; 
        auth.getAccessToken = async () : Promise<string> => {
            let token = await getIdToken();
            return token?.__raw;
        }
        const getIdToken = async () => {
            let tk = ls.get(idTokenKey);
            let expiry = ls.get(expiryIdtokenKey);
            const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
            if (tk && expiry && expiry > currentTimestampInSeconds) {
                return tk;
            }
            let new_token = await auth0.getIdTokenClaims();;
            
            ls.set(idTokenKey, new_token);
            ls.set(expiryIdtokenKey, new_token?.exp);

            return new_token;
        }
        auth.getUserInfo = async () => {
            auth.isLoading = () => true;
            var user: ConstruCoderAuthUserProps | undefined = ls.get(userKey);
            try {
                if (!user) {
                    let token = await getIdToken();
                    user = 
                    (!token || !token![`${namespace}/roles`]) ? 
                        undefined :
                        {
                            email: auth0.user!.email,
                            roles: token![`${namespace}/roles`],
                            id_token: token?.__raw,
                            name: token?.name,
                            profilePhoto: getAvatar(token!.name!),
                            isAdmin: (token![`${namespace}/roles`] as string).includes('Admin')
                        };
                    ls.set(userKey, user);
                }
                if (user && !user.roles) {
                    ls.set(erroStatusKey, 403);
                }
            } catch (error) {
                user = undefined;
                setAppUser(undefined);
            }
            auth.isLoading = () => false;
            return user;
        };
        auth.login = async () =>  {
            return await auth0.loginWithRedirect();
        }
        auth.logout = async () => {
            ls.remove(userKey);
            ls.remove(erroStatusKey);
            return await auth0.logout({ 
                returnTo: window.location.origin,
                localOnly: false,
                client_id: auth0Config.auth0_client_id
            });
        }
        auth.isAuthenticated = () => auth0.isAuthenticated;
        auth.isLoading = () => auth0.isLoading;


    } else {

    }

    
    return { ...auth }
}