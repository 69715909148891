import build from "@date-io/moment";

/**
* URLS
* 
* Use of `?sm=ture` or `&sm=true` at convenience in query params make the request reach the network layer
*
* */
const ignoreMockServerQueryStr = ""

export const prefixer = process.env.REACT_APP_PROD_API_URL;
// export const prefixer1 = process.env.REACT_APP_PROD_1_API_URL;
// export const prefixer2 = process.env.REACT_APP_PROD_2_API_URL;
const buildRoute = (route: string, options?: {
  index?: number;
}) => (route.endsWith(ignoreMockServerQueryStr)
  ? [ prefixer ]
  : "/api/v1"
) + route

export const sloofUrls = {
  CREATE_SLOOF: buildRoute("/sloofs"),
  GET_SLOOFS: buildRoute("/sloofs"),
  UPDATE_SLOOFS: buildRoute("/sloofs"),
  DELETE_SLOOFS: (id: string) => buildRoute(`/sloofs/${id}`),
}

export const paalUrls = {
  CREATE_PAAL: (id: string) => buildRoute("/paals/" + id),
  GET_PAALS: (id: string) => buildRoute("/paals/" + id),
  UPDATE_PAALS: (id: string) => buildRoute("/paals" + id),
  DELETE_PAALS: (id: string) => buildRoute(`/paals/${id}?`),
}

export const grondopbouwUrls = {
  CREATE_GRONDOPBOUW: (id: string) => buildRoute("/grondopbouws/" + id),
  GET_GRONDOPBOUWS: (id: string) => buildRoute("/grondopbouws/" + id),
  UPDATE_GRONDOPBOUWS: (id: string) => buildRoute("/grondopbouws/" + id),
  DELETE_GRONDOPBOUWS: (id: string) => buildRoute(`/grondopbouws/${id}?`),
}

export const oplegingenUrls = {
  CREATE_OPLEGINGEN: (id: string) => buildRoute("/oplegingens/" + id),
  GET_OPLEGINGENS: (id: string) => buildRoute("/oplegingens/" + id),
  UPDATE_OPLEGINGENS: (id: string) => buildRoute("/oplegingens/" + id),
  DELETE_OPLEGINGENS: (id: string) => buildRoute(`/oplegingens/${id}?`),
}

export const instellingenPaalfunderingenUrls = {
  CREATE_INSTELLINGEN_PAALFUNDERINGEN: (id: string) => buildRoute("/instellingen_paalfunderingens" + id),
  GET_INSTELLINGEN_PAALFUNDERINGENS: (id: string) => buildRoute("/instellingen_paalfunderingens" + id),
  UPDATE_INSTELLINGEN_PAALFUNDERINGENS: (id: string) => buildRoute("/instellingen_paalfunderingens" + id),
  DELETE_INSTELLINGEN_PAALFUNDERINGENS: (id: string) => buildRoute(`/instellingen_paalfunderingens/${id}?`),
}

export const projectUrls = {
  GET_MAIN_PROJECT: (number: string, includeChildren = true) => buildRoute(`/Projects?number=${number}&includeChildren=${includeChildren}&sm=true`),
  CREATE_PROJECT: buildRoute("/projects"),
  GET_PROJECTS: buildRoute("/projects"),
  GET_PROJECT: (id: string) => buildRoute(`/projects/${id}`),
  UPDATE_PROJECT: (user: string, isAdmin: boolean) => buildRoute(`/projects/${user}/${isAdmin}`),
  UPDATE_PROJECT_INSTELLIGEN: (id: string) => buildRoute(`/projects/${id}/instelligen`),
  UPDATE_PROJECT_PROP: (id: string, prop: string) => buildRoute(`/projects/${id}/${prop}`),
  PROJECT_PROP: (prop: string) =>   {
    
    return buildRoute(`/projects/${prop}&` + ignoreMockServerQueryStr)
  },
  GET_PROJECT_FINAL_JSON: (id: string) => buildRoute(`/projects/${id}/final-json`),
  DELETE_PROJECT: (id: string | number) => buildRoute(`/projects/${id}`),
}

export const constructionPartsUrls = {
  GET_CONSTRUCTION_PARTS: (constructionId?: number) => buildRoute(`/projects/${constructionId}/ConstructionParts`),
  CERATE_CONSTRUCTION_PART: buildRoute(`/projects/ConstructionParts`),
  UPDATE_CONSTRUCTION_PART: buildRoute(`/projects/ConstructionParts`),
  DELETE_CONSTRUCTION_PART: (id: number, user?: string) => buildRoute(`/projects/ConstructionParts/${id}${user ? `?user=${user}` : ''}`)
}

export const constructionsUrls = {
  GET_CONSTRUCTIONS: (projectId?: number | number, projectNumber?: string) => buildRoute(`/projects/${projectId}/Constructions`),
  CREATE_CONSTRUCTION: buildRoute(`/projects/constructions`),
  UPDATE_CONSTRUCTION:  buildRoute(`/projects/constructions`),
  DELETE_CONSTRUCTION: (id: number, user?: string) => buildRoute(`/projects/constructions/${id}${user ? `?user=${user}` : ''}`)
}

export const objectItemsUrls = {
  GET_OBJECT_ITEMS: (construction_part_id: number) => buildRoute(`/projects/${construction_part_id}/objects`),
  GET_WORKFLOW: (object_id: number, user: string) => buildRoute(`/projects/objects/${object_id}/workflow/${user}`),
  CERATE_OBJECT_ITEM: buildRoute(`/projects/objects`),
  UPDATE_OBJECT_ITEM: buildRoute(`/projects/objects`),

}

export const rekenModelUrls = {
  GET_LINEAR_CALCULATION: (object_id: string) => buildRoute(`/scia/${object_id}/lin`, {
    index: 2,
  }),
  CREATE_LINEAR_CALCULATION: (object_id: number | string) => buildRoute(`/scia/${object_id}/lin`, {
    index: 2,
  }),
}

export const reportsUrls = {
  GET_REBAR_INFO: (object_id: number, reference: string) => buildRoute(`/report/rebar/${object_id}/${reference}`, { index: 2 }),
  CREATE_REBAR_DRAWING: (object_id: number) => buildRoute(`/report/rebar/${object_id}`, { index: 2 }),
  CREATE_REPORT: (object_id: number, reference: string) => buildRoute(`/report/${object_id}/${reference}`, { index: 2 })
}

export const toetsingUrls = {
  GET_PILEHEAD_INFO: (object_id: number | string) => buildRoute(`/idea/pilehead/${object_id}?loadCheckSections=true`, {
    index: 2
  }),
  SAVE_PILEHEAD_INPUT: () => buildRoute(`/idea/pilehead`, {
    index: 2
  }),
  CREATE_RCS_CALCULATION: () => buildRoute('/idea/rcs', { index: 2 }),
  
  GET_RCS_RESULTS: (object_id: number) => buildRoute(`/idea/rcs/${object_id}`, { index: 2 }),

  GET_SLOOF: (object_id: number, type: number, reference: string) => buildRoute(`/sloofcalculations/${object_id}/${type}/${reference}`, { index: 2}),
  CALCULATE_SLOOF: (object_id: number, type: number, reference: string) => buildRoute(`/sloofcalculations/${object_id}/${type}/${reference}`, { index: 2}),
  UPDATE_SLOOF: (object_id: number) => buildRoute(`/sloofcalculations/${object_id}`, { index: 2}),
}


export const landheadsUrls = {
  GET_LANDHEAD: (id: number) => buildRoute(`/parameters/${id}`, {
    index: 1,
  }),
  GET_INFO: (id: number) => buildRoute(`/parameters/info/${id}`, { index: 1}),
  GET_LANDHOOF_REKENMODEL: (object_id: string) => buildRoute(`/scia/${object_id}/lin`, {
    index: 2,
  }),
  CREATE_LANDHEAD: (id: number, reference: string) => buildRoute(`/parameters/${id}/${reference}`, {
    index: 1,
  }),
  UPDATE_LANDHEAD: (id: number, reference: string) => buildRoute(`/parameters/${id}/${reference}`, {
    index: 1,
  }),
  DELETE_LANDHEAD: (id: number) => buildRoute(`/parameters/${id}`, {
    index: 1,
  }),
}

export const toolsUrls = {
  GET_TOOLS: buildRoute(`/tools`),
  GET_TOOL: (id?: number, code?: string) => buildRoute(`/tools/${id ?? 0}?code=${code ?? ''}`),
  GET_TOOL_INPUT: (user: string, code: string) => buildRoute(`/toolinputs/${code}?user=${user}`),
  UPDATE_TOOL_INPUT: (code: string) => buildRoute(`/toolinputs/${code}`),
  CREATE_TOOL_INPUT: (code: string) => buildRoute(`/toolinputs/${code}`),

  PROCESS_TOOL_ACTION: buildRoute(`/toolinputs`),
  GET_TOOL_ACTION_RESULT: (code: string, user: string) => buildRoute(`/toolinputs/results?code=${code}&owner=${user}`)
}

