import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    customer_logo: PropTypes.string | undefined,
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({ customer_logo, disabledLink, sx}) {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR
    // const logo = '/logo/logo_single.svg';

    const logo = (
        <Box component={'div'} sx={{height: 40, display: 'flex'}}>
            <img src="/construcoder_logo.png" style={{ marginRight: 10}}  />
            {customer_logo && (<img  height={38} src={customer_logo} />)}

        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
