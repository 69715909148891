import React, {useEffect, useState} from 'react';
import {ErrorHandler} from 'components';
import styles from './Application.module.css';
import { Theme} from "@mui/material";
import { makeStyles } from "@mui/styles"
import DashboardHeader from './header/customHeader';

const useStyles = makeStyles((theme: Theme) => ({
    topBar: {
        minHeight:'52px',
        paddingTop:'9px',
        paddingBottom:'9px'
    }
}));

const Application = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const classes = useStyles();

  useEffect(() => {
    
  }, [isMobileNavOpen])
  return (
    <div className={styles.root}>
      <ErrorHandler>
        <DashboardHeader className={classes.topBar} onMobileNavOpen={() => setMobileNavOpen(true)}  />
      </ErrorHandler>
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ErrorHandler>{children}</ErrorHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
