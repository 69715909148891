import { sendRequest } from "utils/request_sender"
import { landheadsUrls } from "./urls"
import { error } from "console";

export class ParameterService {

    constructor() { }

    getParameters = async (
        token: string,
        setLoading: Function,
        objectId: number,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: landheadsUrls.GET_LANDHEAD(objectId),
            cb: (data: any) => {
                cb(data);
            },
            failCb: (err, status) => {
                failCb({error: err, status: status});
            }
        })
    }

    getParametersInfo = async (
        token: string,
        setLoading: Function,
        objectId: number,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: landheadsUrls.GET_INFO(objectId),
            cb: (data: any) => {
                cb(data);
            },
            failCb: (err, status) => {
                failCb({error: err, status: status});
            }
        })
    }

    updateParameters = async (
        token: string,
        setLoading: Function,
        data: any,
        objectId: number,
        user: string,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: landheadsUrls.UPDATE_LANDHEAD(objectId, user),
                method: 'put',
                body: { ...data },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )
    }

    createParameters = async (
        token: string,
        setLoading: Function,
        data: any,
        objectId: number,
        user: string,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: landheadsUrls.CREATE_LANDHEAD(objectId, user),
                method: 'post',
                body: { ...data },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )
    }
}