import React, { 
    useMemo,
    useEffect,
    useReducer,
    useState,
    PropsWithChildren
} from "react"
import config from 'config';
import { MsalProvider, useMsal } from "@azure/msal-react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { PublicClientApplication } from "@azure/msal-browser";
import authConfig, { auth0Config, msalConfig } from "authConfig";
export type ConstruCoderProviderType = 'auth0' | 'aad' | string;
export type ConstruCoderAuthProviderProps = PropsWithChildren<{
    provider: ConstruCoderProviderType,
    onRedirectCallback?: Function
}>;

export interface ConstruCoderAuthUserProps {
    id?: number;
    providerId?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    name?: string;
    id_token?: string;
    roles: string[]
    profilePhoto?: string;
    isAdmin: boolean;
}
interface ConstruCoderAuthContextProps {
    provider: string;
    isLoading: () => boolean;
    isAuthenticated: () => boolean;
    appAccess: () => boolean;
    login: Function;
    logout: Function;
    getUserInfo: (config?: any) => Promise<any>;
    getAccessToken: (config?: any) => Promise<any>;
    user: ConstruCoderAuthUserProps | undefined;
    errorStatus: string | undefined;
}

export const initialAuthContext: ConstruCoderAuthContextProps = {
    provider: ``,
    isLoading: () =>  false,
    isAuthenticated: () => false,
    appAccess: () => false,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    getUserInfo: () => Promise.resolve(),
    getAccessToken: () => Promise.resolve(),
    user: undefined,
    errorStatus: undefined
} ;

const ConstruCoderAuthContext = React.createContext<ConstruCoderAuthContextProps>(initialAuthContext);
/**
 * ```jsx
 * <ConstruCoderAuthProvider
 *  provider={provider}
 *  onRedirectCallback={onRedirectCallback}>
 *      <App />
 * </ConstruCoderAuthProvider>
 * ```
 * @param item 
 */
const ConstruCoderAuthProvider = (item: ConstruCoderAuthProviderProps): React.ReactElement => {
        
    const contextValue = useMemo(() => {

    }, [

    ]);

    if (item.provider === "aad") {
        const msalInstance = new PublicClientApplication(msalConfig.publicApp);
        return <MsalProvider instance={msalInstance}>{item.children}</MsalProvider>
    } else if (item.provider === "auth0") {
        return <Auth0Provider
            domain={auth0Config.auth0_domain}
            clientId={auth0Config.auth0_client_id}
            redirectUri={auth0Config.redirectUri}
            onRedirectCallback={(appState, user) => { 
                if (item.onRedirectCallback !== undefined) {
                    item.onRedirectCallback(appState, user)
                }
            }}
            advancedOptions={{
                defaultScope: auth0Config.defaultScope
            }}
        >{item.children}</Auth0Provider>
    } else {
        return <ConstruCoderAuthContext.Provider value={{
            ...initialAuthContext,
            provider: item.provider
        }}>
            {item.children}
        </ConstruCoderAuthContext.Provider>
    }
};

export default ConstruCoderAuthProvider;