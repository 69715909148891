import { LoadingButton } from "@mui/lab"
import { Box, Container, Button, Hidden, Link, Drawer, List, ListItem, ListItemButton, ListItemText, IconButton, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Iconify, Logo } from "components";
import { useEffect, useState } from "react";
import { NavLink as RouterLink, useHistory, useLocation, useParams } from "react-router-dom";
import routes from "routes/routes";
import useResponsive from "hooks/useResponsive"
import AppDropdownButton from "components/AppDropdownButton";
import React from "react";
import config from "config";

interface SearchProps {
  title?: string,
  className?: string,
  drawerOpen?: boolean,
  isAdmin?: boolean,
  sub_title_search?: string,
  handleOnClick?: () => void
  handleSearch?: (keyword: string) => void
  closeDrawer: Function,
  user?: any
}

export const SearchComponent = (props: SearchProps) => {

  const { handleOnClick, handleSearch, drawerOpen, closeDrawer, isAdmin } = props

  // Hooks
  const history = useHistory()
  const isMdUp = useResponsive("up", "md")

  // State
  const [keyword, setKeyword] = useState<string>('');
  const [ logo, setLogo] = React.useState<string | undefined>(`${config.customer_prefix}.png`);

  // Constants
  const inputLightColor = "grey.500"
  const links = [
    {
      to: routes.projecten.all,
      label: "Projecten"
    },
    ...(isAdmin ? [{
      to: routes.tools.index,
      label: "Tools"
    }] : []),
  ];

  const getCustomerLogo = async () => {
    const getName = (name: string, ext: string) => `${name}.${ext}`;
    const getResponse =  async (url: string) => await fetch(`${url}?${new Date().getTime()}`);
    const isImage = (response: any) => {
      let contentType = response.headers.get('content-type');  
      return contentType && contentType.includes('image');
    }
    let lg = `${config.customer_prefix}_logo`;
    let url = `/${getName(lg, 'png')}`;
    let response = await getResponse(url);
    if (isImage(response)) {
      setLogo(url);
    } else {
      url = `/${getName(lg, 'svg')}`;
      response = await getResponse(url);
      if (isImage(response)) {
        setLogo(url); 
      } else {
        setLogo(undefined);
      }
    }
  }

  useEffect(() => {
    (async () => {
      await getCustomerLogo();
    })()
  }, []);

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', alignItems: 'stretch', pl: "0 !important" }} >
      <Hidden smDown>
        <Link
          noWrap
          component={RouterLink}
          to={routes.index.index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: "55px",
          }}
        >
          <Logo customer_logo={logo} />
        </Link>
      </Hidden>
      <Hidden mdDown>
        <Stack direction="row" alignItems={"center"} gap={5}>
          {links.map((link, index) => {
            const isActive = useLocation().pathname.startsWith(link.to)
            return <Link
              key={link.to}
              component={RouterLink}
              to={link.to}
              style={{ fontWeight: isActive ? 700 : undefined, color: !isActive ? "#000" : undefined }}
            >
              {link.label}
            </Link>
          })}
        </Stack>
      </Hidden>

      <Hidden mdUp>
        <Drawer sx={{ width: '100vw', maxWidth: 360, }} open={drawerOpen} onClose={() => closeDrawer()}>
          <List>
            <ListItem sx={{ mb: "30px", mx: "auto", width: "100%", display: "flex", justifyContent: "center", borderBottom: "1px solid", borderBottomColor: "grey.100", py: 3 }}>
              <Link
                noWrap
                component={RouterLink}
                to={routes.index.index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: "55px",
                }}
              >
                  <Logo customer_logo={logo} />
              </Link>
            </ListItem>
            {links.map(link => {
              const isActive = useLocation().pathname.startsWith(link.to)
              return (
                <ListItem
                  key={link.to}
                  sx={{
                    backgroundColor: isActive ? "primary.light" : undefined,
                    width: '100vw',
                    maxWidth: 260,
                  }}
                  disablePadding
                >
                  <ListItemButton onClick={() => history.push(link.to)}>
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Drawer>
        <Typography variant="h3" color={'primary.main'} textAlign={'center'} width={'100%'} sx={{ textAlign: 'center'}}>ConstruCoder</Typography>
      </Hidden>

    </Container>
  )

}
