import { IdeaRcsResponse, PaalKopInfo, RcsRequest } from "store/types"
import { toetsingUrls } from "./urls"
import { sendRequest } from "utils/request_sender"

export class TestingService {

    constructor() {}
 
    
    getPileHeadInfo = async (
        token: string,
        setLoading: Function,
        objectId: number,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.GET_PILEHEAD_INFO(objectId),
            method: "get",
            cb: (data: PaalKopInfo) => {
                cb(data)                           
            },
            failCb: (err: any, status: any) => {
                failCb(err, status)
            }
        });
    }

    createPileHead = async (
        token: string,
        setLoading: Function,
        payload: any,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.SAVE_PILEHEAD_INPUT(),
            method: "post",
            cb: (data: any) => {
                cb(data)                           
            },
            failCb: (err: any, status) => {
                failCb({ error: err, status: status })
            },
            body: payload
        });
    }

    getRcs = async (
        token: string,
        setLoading: Function,
        objectId: number,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.GET_RCS_RESULTS(objectId),
            method: "get",
            cb: (data: IdeaRcsResponse) => {
                cb(data)
            },
            failCb: (error, status) => {
                failCb(error, status)
            }
        })  
    }

    createRcsCalculation = async (
        token: string,
        setLoading: Function,
        request: RcsRequest,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
      sendRequest(token, {
        setLoading: setLoading,
        url: toetsingUrls.CREATE_RCS_CALCULATION(),
        method: "post",
        body: request,
        cb: (data: any) => cb(data),
        failCb: (error, status) => failCb(error, status)
      })        
    }

    getSloofs = async (
        token: string,
        setLoading: Function,
        objectId: number,
        type: number,
        user: string,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.GET_SLOOF(objectId, type, user),
            method: "get",
            cb: (data: any) => {
              cb(data)
            },
            failCb: (error, status) => {
             failCb(error, status)
            }
          })
    }

    calculateSloof = async (
        token: string,
        setLoading: Function,
        objectId: number,
        type: number,
        user: string,
        payload: any,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.CALCULATE_SLOOF(objectId, type, user),
            method: 'put',
            body: payload,
            cb: (data: any) => cb(data),
            failCb: (error, status) => {
              failCb(error, status)
            }
          })
    }

    updateSloof = async (
        token: string,
        setLoading: Function,
        objectId: number,
        payload: any,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: toetsingUrls.UPDATE_SLOOF(objectId),
            method: 'put',
            body: payload,
            cb: (data: any) => cb(data),
            failCb: (error, status) => {
                failCb(error, status)
            }
          })
    }
}