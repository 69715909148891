import { string } from "prop-types"
import { constructionPartsUrls, constructionsUrls, objectItemsUrls, projectUrls } from "services/api/urls"
import { Project } from "store/types/ProjectType"
import { sendRequest } from "utils/request_sender"

export class ProjectService {
    constructor() {
    }

    getProjects = async(
        token: string,
        setLoading: Function,
        email: any,
        cb: (data: any) => void,
        failCb: (err: any, status?: any) => void,
        prefix?: string,
        page?: number,
    ) => {
    
        let params = `${(prefix || 'undefined').trim()}${(page ? `?page=${page}` : '')}`;
        let url = projectUrls.PROJECT_PROP(params || '');
        sendRequest(token,
        {
            setLoading: setLoading,
            url: url,
            method: 'get',
            cb: (data: Project[]) => cb(data),
            failCb: (err, status) => failCb(err, status)
        })        
    }

    getProjectDetails =  async(
        token: string,
        setLoading: Function,
        projectId: any,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading,
            url: projectUrls.GET_MAIN_PROJECT(String(projectId)),
            method: "get",
            cb: (data: Project) =>  cb(data),
            failCb: (err, status) => failCb(err, status)
        });
    }

    createProject = async (
        token: string,
        setLoading: Function,
        data: any,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        
        sendRequest(token, {
            setLoading: setLoading,
            url: projectUrls.CREATE_PROJECT,
            method: 'post',
            body: {...data},
            cb: (data:any) => {
                cb(data);
            },
            failCb: (err: any) => {
                failCb(err);
            }
        })
    }
    updateProject = async (
        token: string,
        setLoading: Function,
        data: any,
        user: string,
        isAdmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void 
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: projectUrls.UPDATE_PROJECT(user, isAdmin),
                method: 'put',
                body: { ...data },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )
    }

    createKw = async (
        token: string,
        setLoading: Function,
        data: any,
        cb: (data: any) => void,
        failCb: (err: any, status?: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionsUrls.CREATE_CONSTRUCTION,
            method: "post",
            cb: (data: any) => {
                cb(data);
            },
            body: data,
            failCb: (error, status) => {
                failCb(error, status);
            }
        })
    }

    updateKW = async (
        token: string,
        setLoading: Function,
        data: any,
        project_number: string,
        cb: (data: any) => void,
        failCb: (err: any) => void 
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: constructionsUrls.UPDATE_CONSTRUCTION,
                method: 'put',
                body: { ...data, project_number: project_number,  },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )        
    }

    deleteKw = async(
        token: string,
        setLoading: Function,
        id: number,
        cb: (data: any) => void,
        failCb: (err: any, status: number) => void,
        user?: string,
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionsUrls.DELETE_CONSTRUCTION(id, user),
            method: 'delete',
            cb: (data: any) => cb(data),
            failCb: (err: any, status: any) => failCb(err, status)
        })
    }

    getConstructionParts = async (
        token: string,
        setLoading: Function,
        construction_id: number,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.GET_CONSTRUCTION_PARTS(construction_id),
            method: 'get',
            cb: (data: any) => {
                
                cb(data);
            },
            failCb: (err: any, status: any) => {
                
                failCb(err);
            }
        })
    }
    createConstructionPart = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.CERATE_CONSTRUCTION_PART,
            method: "post",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }

    updateConstructionPart = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.UPDATE_CONSTRUCTION_PART,
            method: "put",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }

    deleteConstructionPart = async (
        token: string,
        setLoading: Function,
        id: number,
        user: string,
        cb:(data: any) => void,
        failCb: (err: any, status: number) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.DELETE_CONSTRUCTION_PART(id, user),
            method: 'delete',
            cb: (data: any) => cb(data),
            failCb: (err: any, status: any) => failCb(err, status)
        })
    }

    createObject = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.CERATE_OBJECT_ITEM,
            method: "post",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }

    updateObject = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any, status?: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.UPDATE_OBJECT_ITEM,
            method: "put",
            cb: (data: any) => {
              cb(data);
            },failCb:(err, status) => {
              failCb(err, status)
            },
            body: payload
          })
    }

    getObjects = async (
        token: string,
        setLoading: Function,
        construction_part_id: number,
        cb: (data: any) => void,
        failCb: (err: any, status?: any) => void
    ) => {
        
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.GET_OBJECT_ITEMS(construction_part_id),
            method: 'get',
            cb: (data: any) => {
                cb(data);
            },
            failCb: (err: any, status: any) => {
                failCb(err, status);
            }
        })
    }

    getWorkflow = async (
        token: string,
        setLoading: Function,
        object_id: number,
        user: string,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.GET_WORKFLOW(object_id, user),
            method: 'get',
            cb: (data: any) => cb(data),
            failCb: (err: any, status: any) => failCb(err, status)
        });
    }

}